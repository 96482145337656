'use client';

import styled from 'styled-components';
import MainLink from '@/components/global/typography/links/MainLink';

const WhiteLink = styled(MainLink)`
    &&& {
        text-decoration: underline;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;

        &:hover {
            color: #d8d8d8;
        }
    }
`;

export default WhiteLink;
